'use client';

import Image from 'next/image';

import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import { isDevOrLocalhost } from 'utils/helpers';

import cleeAIResearchLogo from 'public/images/cleeai-logo-research.svg?url';
import trustpilotRating from 'public/images/icons/trustpilotRating.svg?url';
import trustpilotStar from 'public/images/icons/trustpilotStar.svg?url';

import style from './style.module.css';
import UnauthSearchBox from 'components/UnauthSearchBox';
import Link from 'next/link';

export function UnauthHome() {
  const defaultRecommended = isDevOrLocalhost()
    ? defaultRecommendedDev
    : defaultRecommendedProd;

  return (
    <div className={style.unAuthHomeContainer}>
      <div className={style.innerContainer}>
        <div className={style.centered}>
          <Image
            className={style.logo}
            src={cleeAIResearchLogo}
            alt="Clee AI logo"
          />
        </div>
        <h1 className={style.heading}>Ask anything. Trust everything.</h1>
        <h2 className={style.subHeading}>
          Real Time Research & Knowledge with Accurate Sources
        </h2>
        <div className={style.centered}>
          <UnauthSearchBox
            className={style.searchBoxContainer}
          ></UnauthSearchBox>
        </div>
        <div className={style.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
      <Link
        href={'https://www.trustpilot.com/review/cleeai.com'}
        className={style.ratingContainer}
        target="_blank"
      >
        <p className={style.ratingText}>Trusted</p>
        <Image alt="Trustpilot rating" src={trustpilotRating} />
        <div className={style.ratingRight}>
          <Image alt="Trustpilot logo star" src={trustpilotStar} />
          <p className={style.ratingText}>Trustpilot</p>
        </div>
      </Link>
    </div>
  );
}
