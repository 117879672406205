'use client';

import React from 'react';
import Button from 'components/Button';
import style from './style.module.css';
import { TagQs, ANON_ID_FOR_SUGGESTED_QUERIES } from './defaultRecommended';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import { useSearchProvider } from 'providers/SearchProvider';

interface Props {
  recommendedQs: TagQs[];
}

export default function RecommendedQsDesktop({ recommendedQs }: Props) {
  const searchProvider = useSearchProvider();
  const [user] = useAuth();

  return (
    <div className={style.qsContainer}>
      {recommendedQs.map((tagQs) => (
        <div className={style.questionsBox} key={tagQs.tag}>
          <p className={style.subHeadingQA}>{tagQs.tag}</p>
          {tagQs.questions.map((q) => (
            <Button
              className={style.questionBtn}
              variant="tertiary"
              key={q.searchId}
              onClickHandler={() => {
                analytics.clickedSuggestedQuery(
                  user,
                  q.searchId,
                  q.question,
                  tagQs.tag,
                );
                if (user) {
                  // if user is logged in, create a search in user's account
                  searchProvider?.search.create(q.question);
                } else {
                  // if user is not logged in, create an anonymous search
                  searchProvider?.anonymousSearch.returnCachedOrCreate(
                    q.searchId,
                    q.question,
                    ANON_ID_FOR_SUGGESTED_QUERIES,
                    // SUGGESTED_QUERIES_TTL_IN_DAYS,
                  );
                }
              }}
            >
              {q.question}
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
}
