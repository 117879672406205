import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Button from 'components/Button';
import style from './style.module.css';
import { Q } from './defaultRecommended';
import { analytics } from 'lib/analytics';
import { useSearchProvider } from 'providers/SearchProvider';
import { ANON_ID_FOR_SUGGESTED_QUERIES } from './defaultRecommended';
import useAuth from 'hooks/useAuth';

interface Props {
  tag: string;
  questions: Q[];
}

export default function QuestionsBox({ tag, questions }: Props) {
  const searchProvider = useSearchProvider();
  const [user] = useAuth();

  return (
    <div className={style.questionsBox}>
      <p className={style.subHeadingQA}>{tag || <Skeleton count={1} />}</p>
      {questions.map((q) => (
        <Button
          className={style.questionBtn}
          variant="tertiary"
          key={q.searchId}
          onClickHandler={() => {
            analytics.clickedSuggestedQuery(user, q.searchId, q.question, tag);
            if (user) {
              // if user is logged in, create a search in user's account
              searchProvider?.search.create(q.question);
            } else {
              // if user is not logged in, create an anonymous search
              searchProvider?.anonymousSearch.returnCachedOrCreate(
                q.searchId,
                q.question,
                ANON_ID_FOR_SUGGESTED_QUERIES,
                // SUGGESTED_QUERIES_TTL_IN_DAYS,
              );
            }
          }}
        >
          {q.question || <Skeleton count={2} />}
        </Button>
      ))}
    </div>
  );
}
