import { FormEvent } from 'react';

import { useSearchProvider } from 'providers/SearchProvider';
import ErrorMessage from 'components/ErrorMessage';
import ModalCreateAccount from 'components/ModalCreateAccount';
import SearchBox from 'components/SearchBox';

interface Props {
  className: string;
}

export default function UnauthSearchBox({ className }: Props) {
  const searchProvider = useSearchProvider();
  const anonymousQA = searchProvider?.anonymousSearch.getAnonymousQA();
  const errorMessage =
    searchProvider?.anonymousSearchForm.getErrorMessage(false);

  async function handleInputOnSubmit(ev: FormEvent<HTMLFormElement>) {
    searchProvider?.anonymousSearchForm.onSubmit(ev);
  }

  return (
    <>
      <div className={className}>
        <SearchBox
          readOnly={false}
          isDisabled={
            searchProvider?.anonymousSearchForm.isDisabled() ||
            anonymousQA?.getIsStreaming(false)
          }
          onSubmitCB={handleInputOnSubmit}
          onChangeCB={(ev) =>
            searchProvider?.anonymousSearchForm.onValueChange(ev)
          }
          value={
            searchProvider?.anonymousSearchForm.getSearchInputValue(false) || ''
          }
        />
        {Boolean(errorMessage) ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : null}
      </div>
      <ModalCreateAccount
        isOpen={searchProvider?.anonymousSearch.getIsModalOpen(false) ?? false}
        onCloseCB={() => {
          searchProvider?.anonymousSearch.setIsModalOpen(false);
        }}
      />
    </>
  );
}
