'use client';

import useAuth from 'hooks/useAuth';
import { useSearchProvider } from 'providers/SearchProvider';
import { analytics } from 'lib/analytics';

import SearchBox from 'components/SearchBox';
import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import { isDevOrLocalhost } from 'utils/helpers';

import style from './style.module.css';
import ErrorMessage from 'components/ErrorMessage';

export function AuthHome() {
  const [user] = useAuth();
  const search = useSearchProvider();

  const defaultRecommended = isDevOrLocalhost()
    ? defaultRecommendedDev
    : defaultRecommendedProd;

  return (
    <div className={style.authHomeContainer}>
      <div className={style.authContainerScrollable}>
        <h1 className={style.headingAuth}>Ask anything. Trust everything.</h1>
        <div className={style.centeredSearchContainer}>
          <div
            className={style.searchBoxContainer}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault;
              event.stopPropagation();
            }}
          >
            <SearchBox
              onSubmitCB={(ev) => {
                analytics.questionAsked(
                  user,
                  search?.searchForm.getSearchText(),
                );
                search?.searchForm.onSubmit(ev);
              }}
              onChangeCB={(ev) => search?.searchForm.onTextChanged(ev)}
              value={search?.searchForm.getSearchText() ?? ''}
            />
          </div>
          {Boolean(search?.searchForm.getErrorMessage()) && (
            <ErrorMessage className={style.errorMessage}>
              {search?.searchForm.getErrorMessage()}
            </ErrorMessage>
          )}
        </div>
        <div className={style.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
    </div>
  );
}
