'use client';

import { useRouter } from 'next/navigation';
import { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'sonner';

import { registerAutoLogin } from 'lib/autologin';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';

import {
  AuthFormTitle,
  AuthFormHelpLink,
  AuthFormSubmitButton,
  AuthFormGoogleButton,
} from 'components/AuthForm';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input';
import Label from 'components/Label';
import PasswordError from 'components/PasswordError';

import { createAccountSchema } from 'utils/validationSchemas';
import { genericErrorMap } from 'utils/genericError';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import ServerAPIError from 'utils/ServerAPIError';

import style from './style.module.css';
import Ratings from 'components/Ratings';

const defaultFieldErrors = {
  email: [],
  password: [],
};

interface Props {
  onIsLoadingCB: (isLoading: boolean) => void;
  passwordErrorVariant?: 'toast' | 'embedded';
}

export default function CreateAccountForm({
  onIsLoadingCB,
  passwordErrorVariant = 'toast',
}: Props) {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fieldErrors, setFieldErrors] = useState<{
    email?: string[];
    password?: string[];
  }>(defaultFieldErrors);
  const [genericError, setGenericError] = useState('');

  async function validate() {
    const validation = createAccountSchema.safeParse({ email, password });

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      if (
        Boolean(errors?.fieldErrors?.password?.length) &&
        passwordErrorVariant === 'toast'
      ) {
        toast.error(<PasswordError />, {
          duration: 60000,
          position: 'top-center',
        });
      }
      setFieldErrors(errors?.fieldErrors);

      return false;
    }

    try {
      const response = await fetchWithCredentials(
        `${
          process.env.NEXT_PUBLIC_SERVER_BASE_URL
        }/utils/is-valid-domain?email=${encodeURIComponent(email)}`,
      );
      const resJson = await response.json();

      if (!response.ok) {
        throw new ServerAPIError(
          `${resJson?.error?.message ?? 'Unexpected error'}`,
          response,
        );
      }

      setFieldErrors(defaultFieldErrors);
      return true;
    } catch (err) {
      const fieldErrors = {
        email: ['Please enter your work email or contact hi@cleeai.com'],
        password: [],
      };

      setFieldErrors(fieldErrors);

      return false;
    }
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    const { name, value } = ev.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }

  async function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    setGenericError('');
    onIsLoadingCB(true);

    const isValid = await validate();
    if (!isValid) {
      onIsLoadingCB(false);
      return;
    }

    try {
      await trpc.auth.signUp.mutate({ email, password });
      await registerAutoLogin(email, password);
      analytics.preverified(email);
      router.push(`/verify?email=${encodeURIComponent(email)}`);
    } catch (err) {
      const genericError =
        genericErrorMap[err.message] ??
        'There has been a problem, please try again';
      setGenericError(genericError);
      console.error('error signing up:', err);
    } finally {
      onIsLoadingCB(false);
    }
  }

  return (
    <>
      <AuthFormTitle>Welcome to CleeAI!</AuthFormTitle>
      <AuthFormGoogleButton
        onClickCB={async () => {
          const params = new URLSearchParams({
            redirect_uri: process.env.NEXT_PUBLIC_OAUTH_REDIRECT || '',
            response_type: 'code',
            client_id: '',
            identity_provider: 'Google',
            scope: '',
            state: 'state',
            code_challenge: '',
            code_challenge_method: 'S256',
          });
          router.push(
            `http://${process.env.NEXT_PUBLIC_OAUTH_DOMAIN}/oauth2/authorize?${params.toString()}`,
          );
        }}
      >
        Continue with Google
      </AuthFormGoogleButton>
      <form onSubmit={handleSubmit} noValidate>
        <fieldset>
          <>
            <Label htmlFor="email">Work email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChangeCB={handleChange}
              hasError={Boolean(fieldErrors?.email?.length)}
              errorMessage={fieldErrors?.email?.[0] ?? ''}
            />
          </>
          <>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={password}
              onChangeCB={handleChange}
              hasError={Boolean(fieldErrors?.password?.length)}
              errorMessage={fieldErrors?.password?.[0] ?? ''}
            />
          </>
          {Boolean(genericError) && (
            <ErrorMessage className={style.genericError}>
              {genericError}
            </ErrorMessage>
          )}
        </fieldset>
        <AuthFormSubmitButton>Sign up</AuthFormSubmitButton>
        <div className={style.microcopy}>
          By creating an account you accept{' '}
          <a
            href="https://app.termly.io/document/terms-of-service/b3d4ba0d-30c8-497d-ac68-4c9c5c679b9f"
            className={style.textLink}
            target="_BLANK"
            rel="noreferrer noopener"
          >
            Terms and conditions
          </a>{' '}
          and{' '}
          <a
            href="https://www.cleeai.com/policies/privacy-policy"
            className={style.textLink}
            target="_BLANK"
            rel="noreferrer noopener"
          >
            Privacy policy
          </a>
          .
        </div>
        <Ratings />
        <AuthFormHelpLink text="Have an account?">
          <Button
            href="/login"
            variant="tertiary"
            size="small"
            className={style.signinBtn}
          >
            Sign in
          </Button>
        </AuthFormHelpLink>
      </form>
      {Boolean(fieldErrors?.password?.length) &&
      passwordErrorVariant === 'embedded' ? (
        <PasswordError />
      ) : null}
    </>
  );
}
